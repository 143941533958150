.story {
    position: relative;
    flex-wrap: wrap;
    flex-direction: column-reverse;
}
.story .story-logo img {
    width: 100%;
    max-width: 440px;
    max-height: 416px;
}
.story .story-logo {
    text-align: center;
}
.story .story-logo, .story-content {
    position: relative;
    width: 100%;
}
.story .story-content .title {
    font-size: 26px;
    font-weight: 400;
    line-height: 32px;
}
.story .story-content .subtitle {
    font-size: 18px;
    font-weight: 100;
}
@media only screen and (min-width: 48em) {
    .story {
        flex-wrap: nowrap;
        flex-direction: unset;
    }
    .story.flex-direction-row-reverse {
        flex-direction: row-reverse;
    }
    .story .story-content > div {
        max-width: 88%;
        margin-left: auto;
    }
    .story.flex-direction-row-reverse .story-content > div {
        margin-right: auto;
    }
    .story .story-logo, .story .story-content {
        width: 50%;
    }
    .story .story-content .title {
        font-size: 38px;
        line-height: 44px;
    }
    .story .story-content .subtitle {
        font-size: 22px;
    }
}

@media only screen and (min-width: 78rem) {
    .story .story-content .title {
        font-size: 42px;
    }
}
