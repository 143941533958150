section.page-exp .exps {
    padding-top: 16px;
    padding-bottom: 80px;
    max-width: 62rem;
    margin: 0 auto;
    width: 100%;
}

section.page-exp .exps > h1 {
    position: relative;
    text-align: center;
}

.page-exp .btn-exp {
    max-width: 150px;
    margin: 0 auto;
}

section.page-exp .exp-item:not(:last-child) {
    border-bottom: 1px solid var(--border-color-light);
}


@media only screen and (max-width: 480px) {}

@media only screen and (min-width: 48em) {}

@media only screen and (min-width: 78rem) {}