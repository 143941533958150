.page-result .form-container {
    width: 100%;
    padding: 40px 20px;
    margin: 40px auto;
    font-size: 18px;
    max-width: 960px;
    min-height: 652px;
    text-align: center;
    margin-top: 32px;
}
.page-result h1 {
    font-weight: 600;
}
.page-result .result-icon {
    max-width: 152px;
    width: 100%;
    margin: 0 auto;
    display: block;
}
.page-result p {
    max-width: 480px;
    margin: 0 auto;
}
.btn-result-redirect {
    display: inline-block;
}