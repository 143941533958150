section.page-award .awards {
    padding-top: 16px;
    padding-bottom: 80px;
    max-width: 62rem;
    margin: 0 auto;
    width: 100%;
}

section.page-award .awards > h1 {
    position: relative;
    text-align: center;
}

.page-award .btn-award {
    max-width: 150px;
    margin: 0 auto;
}

section.page-award .award-item:not(:last-child) {
    border-bottom: 1px solid var(--border-color-light);
}


.page-award .award-image {
    margin-top: 2px;
    margin-bottom: 2px;
}
.page-award .award-items img:nth-child(odd):not(:last-child) {
    padding-right: 4px;
}
.page-award .award-items img:nth-child(even):not(:last-child)  {
    padding-left: 4px;
}


@media only screen and (max-width: 480px) {}

@media only screen and (min-width: 48em) {}

@media only screen and (min-width: 78rem) {
    .page-award .award-items img:not(:last-child) {
        width: 50%;
        max-height: 656px;
    }
}