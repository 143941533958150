.page-invalid .form-container {
    width: 100%;
    padding: 40px 20px;
    margin: 0 auto;
    font-size: 18px;
    max-width: 960px;
    text-align: center;
}
.page-invalid h1 {
    font-weight: 600;
}
.page-invalid .invalid-icon {
    max-width: 280px;
    width: 100%;
    margin: 0 auto;
    display: block;
}

.page-invalid p {
    max-width: 480px;
    margin: 0 auto;
}

.btn-invalid-redirect {
    display: inline-block;
}

section.page-invalid .invalid {
    padding-top: 40px;
    padding-bottom: 80px;
}

@media only screen and (max-width: 480px) {}

@media only screen and (min-width: 48em) {}