.btn {
    padding: 6px 12px;
    font-size: 14px;
    height: 36px;
    cursor: pointer;
    -webkit-transition: all 0.5s ease-out;
    -o-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
    width: 100%;
    border-radius: var(--border-radius);
    border: 0;
}

.btn.neutral {
    border: 1px solid var(--border-color-primary);
    background-color: transparent;
    color: var(--text-color-primary);
}

.btn.brand {
    background-color: var(--bg-color-brand);
    color: var(--text-color-invert);
}

.btn.neutral:hover {
    background-color: var(--bg-color-hover-light);
}
.btn.brand:hover {
    background-color: var(--bg-color-hover-brand);
}
button span {
    font-family: 'torus', sans-serif;
}
@media only screen and (min-width: 48em) {
    .btn {
        padding: 8px 16px;
        font-size: 16px;
        height: 44px;
    }
}


@media only screen and (min-width: 78rem) {}