:root {
    --bg-glass-color-header: #ffffffeb;
    --border-glass-color-header: #92002f61;
    --page-pad-large: 100px;
    --page-pad-medium: 70px;
    --page-pad-small: 20px;

    --content-pad-large: 70px;
    --content-pad-medium: 60px;
    --content-pad-small: 20px;
    --content-pad-xsmall: 12px;

    --text-color-primary: #3A3D44;
    --text-color-invert: #fff;
    --text-color-invert-light: #b8b8b8;
    --text-color-invert-lighter: #dedede;
    --text-color-brand: #92002f;
    --text-color-error: #b32626;
    --text-color-brand-shadow-white: -1px 1px #ffffff38;
    --border-color-primary: #000;
    --border-color-neutral: #000;
    --border-color-light: #dcdbda;
    --border-color-brand: #87002b;
    --border-color-error: #db5151;
    --bg-color: #fff;
    --bg-color-brand: #92002f;
    --bg-color-hover: #f0f0f0;
    --bg-color-hover-light: #f0f0f0;
    --bg-color-hover-brand: #92002ecc;
    --border-radius: 8px;
    --border-radius-input: 4px;

    --text-title-size-large: 60px;
    --text-title-lineheight-size-large: 68px;
    --text-subtitle-size-large: 32px;
    --text-p-size-large: 20px;

    --text-title-size-medium: 48px;
    --text-title-lineheight-size-medium: 56px;
    --text-subtitle-size-medium: 24px;
    --text-p-size-medium: 18px;

    --text-title-size-small: 30px;
    --text-title-lineheight-size-small: 38px;
    --text-subtitle-size-small: 20px;
    --text-p-size-small: 16px;
}

section.content {
    width: 100%;
    margin: 0 auto;
    padding: 0 var(--page-pad-small);
}

label {
    display: block;
    padding-bottom: 3px;
}

input,
textarea,
select {
    height: 32px;
    border-radius: var(--border-radius-input);
    border: 1px solid rgb(221, 219, 218);
    padding: 0 14px;
    width: 100%;
}

textarea {
    height: unset;
    padding: 14px;
}

textarea:focus,
input:focus,
select:focus {
    outline: none;
}
a {
    text-decoration: none;
    color: var(--text-color-brand);
}
a:hover {
    color: var(--text-color-primary);
}
.jf-error input {
    border: 1px solid var(--border-color-error);
}

.text-brand {
    color: var(--text-color-brand);
}
.text-brand-bg-dark {
    color: var(--text-color-brand);
    text-shadow: var(--text-color-brand-shadow-white);
}
.text-invert-light {
    color: var(--text-color-invert-lighter);
}

span.jf-error {
    color: var(--text-color-error);
    font-size: 13px;
}

.flex {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

.flex-sb {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.flex-align-center {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.flex-justify-center {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.flex-direction-row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
}

.flex-direction-column {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.flex-direction-row-reverse {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
}

.flex-direction-column-reverse {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
}

.flex-wrap {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.jf-hide {
    display: none;
}

.jf-p-left_xsmall {
    padding-left: 8px;
}

.jf-p-left_small {
    padding-left: 12px;
}

.jf-p-right_small {
    padding-right: 12px;
}
.jf-p-vertical_xsmall {
    padding-top: 8px;
    padding-bottom: 8px;
}
.jf-p-vertical_small {
    padding-top: 12px;
    padding-bottom: 12px;
}

.jf-p-vertical_medium {
    padding-top: 16px;
    padding-bottom: 16px;
}

.jf-p-vertical_large {
    padding-top: 24px;
    padding-bottom: 24px;
}

.jf-m-top_small {
    margin-top: 12px;
}
.jf-m-top_medium {
    margin-top: 16px;
}
.jf-m-top_large {
    margin-top: 24px;
}
.jf-m-top_xlarge {
    margin-top: 32px;
}
.jf-m-bottom_small {
    margin-bottom: 12px;
}
.jf-m-bottom_medium {
    margin-bottom: 16px;
}
.jf-m-bottom_large {
    margin-bottom: 24px;
}
.jf-m-bottom_xlarge {
    margin-bottom: 32px;
}
.jf-m-bottom_xxlarge {
    margin-bottom: 40px;
}
.jf-m-vertical_small {
    margin-top: 12px;
    margin-bottom: 12px;
}

.jf-m-vertical_medium {
    margin-top: 16px;
    margin-bottom: 16px;
}

.jf-m-vertical_large {
    margin-top: 24px;
    margin-bottom: 24px;
}

.jf-m-vertical_xlarge {
    margin-top: 32px;
    margin-bottom: 32px;
}

.jf-morphism-brand {
    background: rgba(146, 0, 47, 0.6);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
}

.jf-morphism-white {
    background: rgba(255, 255, 255, 0.6);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(6px);
    -webkit-backdrop-filter: blur(6px);
    border: 1px solid rgba(255, 255, 255, 0.2);
}

.loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #ffffff9e;
    z-index: 1;
}

.spinner {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 20px;
    height: 20px;
    color: var(--text-color-brand);
    background:
        linear-gradient(currentColor 0 0),
        linear-gradient(currentColor 0 0),
        linear-gradient(currentColor 0 0),
        linear-gradient(currentColor 0 0);
    background-size: 16px 16px;
    background-repeat: no-repeat;
    animation: loading 1.5s infinite cubic-bezier(0.3, 1, 0, 1);
}

.page {
    display: block;
    position: relative;
    color: var(--text-color-primary);
    font-size: var(--text-p-size-small);
    width: 100%;
    height: 100vh;
    padding: 0 var(--page-pad-small);
    padding-top: 100px !important;
}

.main-background {
    background-image: url('./assets/images/building.jpg');
    background-size: cover;
    background-position: 45% bottom;
    background-repeat: no-repeat;
}
.page .title {
    font-size: var(--text-title-size-small);
    line-height: var(--text-title-lineheight-size-small);
    font-weight: 600;
}
.page .subtitle {
    font-size: var(--text-subtitle-size-small);
    font-weight: 100;
}
.page .role {
    font-size: var(--text-subtitle-size-small);
    font-weight: 100;
    margin: 8px 0;
}

.page .glass-pads {
    width: 100%;
    padding: 40px 20px;
    font-size: 16px;
}
.page .page-story {
    max-width: 78rem;
    margin: 0 auto;
}
.center {
    text-align: center;
}
.right {
    text-align: right;
}
.component-divider {
    border-top: 1px solid var(--border-glass-color-header);
    padding: 26px 0;
}

@media only screen and (min-width: 48em) {
    section.content {
        padding: 0 var(--page-pad-medium);
    }
    .page {
        padding: 0 var(--page-pad-medium);
        font-size: var(--text-p-size-medium);
    }
    .main-background {
        padding: 0 var(--page-pad-medium);
        background-size: contain;
        background-position: center bottom;
    }

    .page .title {
        font-size: var(--text-title-size-medium);
        line-height: var(--text-title-lineheight-size-medium);
        font-weight: 600;
    }

    .page .subtitle {
        font-size: var(--text-subtitle-size-medium);
    }

    .page .glass-pads {
        padding: 60px 40px;
    }
    .component-divider {
        border-top: 1px solid var(--border-glass-color-header);
        padding: 40px 0;
    }
}

@media only screen and (min-width: 78rem) {
    section.content {
        padding: 0 var(--page-pad-large);
    }
    .page {
        padding: 0 var(--page-pad-large);
        font-size: var(--text-p-size-large);
    }

    .page .title {
        font-size: var(--text-title-size-large);
        line-height: var(--text-title-lineheight-size-large);
        font-weight: 600;
    }

    .page .subtitle {
        font-size: var(--text-subtitle-size-large);
        margin: 8px 0;
    }
}

@keyframes loading {
    0% {
        background-position: 0 0, 100% 0, 100% 100%, 0 100%
    }

    33% {
        background-position: 0 0, 100% 0, 100% 100%, 0 100%;
        width: 40px;
        height: 40px
    }

    66% {
        background-position: 100% 0, 100% 100%, 0 100%, 0 0;
        width: 40px;
        height: 40px
    }

    100% {
        background-position: 100% 0, 100% 100%, 0 100%, 0 0
    }
}
.section-2fr {
    min-height: 100vh;
    display: grid;
    display: -ms-grid;
    -ms-grid-rows: auto 1fr auto;
    grid-template-rows: 1fr auto;
    -ms-grid-columns: minmax(0, 1fr);
    /* grid-template-columns: minmax(0, 1fr); */
}

.section-3fr {
    min-height: 100vh;
    display: grid;
    display: -ms-grid;
    -ms-grid-rows: auto 1fr 1fr auto;
    grid-template-rows: 1fr 1fr auto;
    -ms-grid-columns: minmax(0, 0, 1fr);
    /* grid-template-columns: minmax(0, 1fr); */
}

.bounce { 
    -webkit-animation-name: bounce; 
    animation-name: bounce; 
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both; 
    animation-fill-mode: both;
}
@-webkit-keyframes bounce { 
    0%, 20%, 50%, 80%, 100% {-webkit-transform: translateY(0);} 
    40% {-webkit-transform: translateY(-30px);} 
    60% {-webkit-transform: translateY(-15px);} 
} 
    
@keyframes bounce { 
    0%, 20%, 50%, 80%, 100% {transform: translateY(0);} 
    40% {transform: translateY(-30px);} 
    60% {transform: translateY(-15px);} 
}
