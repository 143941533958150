.certificate .icon-cert {
    width: 100%;
    max-width: 140px;
}

.certificate .cert-logo {
    text-align: center;
}

.certificate .cert-logo,
.certificate .cert-content {
    position: relative;
    width: 100%;
}

.certificate .cert-content .title {
    font-size: 26px;
    font-weight: 400;
    line-height: 32px;
}

.certificate .cert-content .subtitle {
    font-size: 18px;
    font-weight: 100;
}
.certificate .content td {
    padding-right: 8px;
}
.certificate {
    flex-direction: column-reverse;
}
@media only screen and (min-width: 48em) {
    .certificate {
        flex-direction: row;
    }
    .certificate .cert-logo,
    .certificate .cert-content {
        width: 50%;
    }

    .certificate .cert-content .title {
        font-size: 28px;
        line-height: 34px;
    }

    .certificate .cert-content .subtitle {
        font-size: 22px;
    }
}

@media only screen and (min-width: 78rem) {
    .certificate .cert-content .title {
        font-size: 32px;
    }
}