.message input, textarea, select {
    background:  rgba(255, 255, 255, 0.2);
}
.message .form-container {
    width: 100%;
    padding: 40px 20px;
    margin: auto;
    max-width: 960px;
}
.message .title {
    width: 40%;
    display: none;
}
.message .form {
    width: 100%;
}
.message .instruction {
    font-weight: 400;
    padding-bottom: 20px;
    border-bottom: 1px solid var(--border-color-primary);
}
div.message h1 {
    font-size: 28px;
    font-weight: 500;
}
.message .instruction > div:first-child {
    width: 50%;
}
.message .instruction > div:last-child {
    text-align: right;
}
.message .avatar {
    text-align: center;
    width: 80%;
    padding-left: 20px;
}
.message .avatar img {
    width: 100%;
}
.message .img-avatar-mob {
    max-height: 60px;
    display: block;
}
.message .btn-contact {
    max-width: 100%;
}
@media only screen and (max-width: 480px) {
    .message .instruction {
        flex-direction: column-reverse;
    }
    .message .instruction > div:first-child {
        width: 100%;
        padding-top: var(--content-pad-small);
    }
}
@media only screen and (min-width: 48em) {
    div.content {
        padding: 0 var(--page-pad-medium);
    }
    div.message {
        font-size: 18px;
    }
    .message .title {
        display: flex;
        padding-bottom: 60px;
    }
    .message .form {
        width: 60%;
    }
    .message .img-avatar-mob {
        display: none;
    }
    .message .form-container {
        padding: 60px 40px;
    }
    .message .btn-contact {
        max-width: 100px;
        margin-left: auto;
    }
}