.page-about {
    min-height: 768px;
}
.page-about .banner {
    position: relative;
    background-image: url('../assets/images/about.png');
    background-size: cover;
    background-position: 85% 30%;
    background-repeat: no-repeat;
    width: calc(100% + 40px);
    min-height: 500px;
    margin-top: -20px;
    margin-left: -20px;
    margin-right: -20px;
}
.page-about .banner::before {
    content: "";
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 50%;
    background-image: linear-gradient(to left, rgba(255, 0, 0, 0), rgb(0 0 0 / 70%));
}
.page-about .intro-about {
    display: none;
    position: absolute;
    color: #fff;
    width: 50%;
    height: 100%;
    padding: 0 var(--page-pad-small);
}
.intro-about > * {
    transition: all ease 1s !important; 
}
.intro-about-mob {
    display: block;
    text-align: justify;
}
.scroll-more {
    color: #fff;
    position: absolute;
    bottom: 0;
    left: 50%;
    padding: 20px;
    cursor: pointer;
    display: none;
}
.scroll-more .bounce {
    -webkit-animation-iteration-count: 3;
    animation-iteration-count: 3;
}
.story-row {
    padding: 16px 0;
}
.about-contact {
    max-width: 78rem;
    margin: 0 auto;
}
@media only screen and (min-width: 640px) {
    .page-about .banner:before {
        display: block;
    }
    .intro-about-mob  {
        display: none;
    }
    .page-about .intro-about {
        display: flex;
    }
}
@media only screen and (min-width: 48em) {
    .page-about .banner {
        background-position: 85% 30%;
        width: calc(100% + 140px);
        margin-left: -70px;
        margin-right: -70px;
    }
    .page-about .intro-about {
        padding: 0 var(--page-pad-medium);
    }
    .story-row {
        padding: 40px 0;
    }
}
@media only screen and (min-width: 78rem) {
    .scroll-more {
        display: block;
    }
    .page-about .banner {
        background-position: 80% 30%;
        width: calc(100% + 200px);
        margin-left: -100px;
        margin-right: -100px;
        height: calc(100vh - 80px);
    }
    .page-about .intro-about {
        padding: 0 var(--page-pad-large);
    }
}