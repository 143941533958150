section.page-blog .blogs {
    padding-top: 16px;
    padding-bottom: 40px;
    max-width: 62rem;
    margin: 0 auto;
    width: 100%;
}

section.page-blog .blogs > h1 {
    position: relative;
    text-align: center;
}

.page-blog .btn-blog {
    max-width: 150px;
    margin: 0 auto;
}

section.page-blog .blog-item:not(:last-child) {
    border-bottom: 1px solid var(--border-color-light);
}

section.page-blog .tiktok-embed, iframe {
    width: 100%;
    height: 350px;
    max-width: 740px;
}
section.page-blog .tiktok-embed {
    height: 396px;
}

/** Odd tiktok screen breakpoints **/
@media only screen and (min-width: 415px) {
    section.page-blog .tiktok-embed {
        height: 480px !important;
    }
}

@media only screen and (min-width: 78rem) {
    
}