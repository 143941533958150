.header-container {
    height: 80px;
    border-bottom: 1px solid #00000017;
    position: fixed;
    color: var(--text-color-primary);
    top: 0;
    left: 0;
    right: 0;
    min-height: 80px;
    padding: 0 var(--page-pad-small);
    transition: all 0.6s ease-in-out;
    z-index: 10;
}
.header-logo {
    overflow: hidden;
}
.intro {
    position: relative;
    z-index: 1;
}

.logo, .logo-image {
    height: 26px;
    cursor: pointer;
    text-decoration: none;
    letter-spacing: 3px;
    font-size: 18px;
    color: var(--text-color-primary);
}
.logo span {
    padding-left: 16px;
}

@media only screen and (min-width: 48em) {
    .header-container {
        padding: 0 var(--page-pad-medium);
    }
}

.desk-nav {
    margin-left: 20px;
    display: none;
}

.desk-nav li {
    display: inline-block;
    position: relative;
}

.desk-nav li label {
    font-size: 18px;
    padding: 12px 26px;
    cursor: pointer;
}

.desk-nav li label:hover {
    background-color: var(--bg-color-hover);
    border-radius: var(--border-radius);
}

.desk-nav li ul {
    position: absolute;
    padding: 12px 0;
    top: 62px;
    left: 0;
    background-color: #fff;
    border-radius: var(--border-radius);
    color: var(--text-color-primary);
    border: 1px solid var(--border-color-light);
    min-width: 224px;
}

.desk-nav li ul li {
    font-size: 18px;
    padding: 8px 18px;
    min-width: 188px;
    width: 100%;
    cursor: pointer;
}

.desk-nav li ul li:hover {
    background-color: var(--bg-color-hover-light);
}

.desk-nav li ul li svg {
    width: 28px;
}

.nav-menus {
    background-color: #fff;
}
.nav-menus:not(.active),
.header-actions .send-email {
    display: none;
}

.scrolled.header-container {
    background-color: var(--bg-glass-color-header);
    border-bottom: 1px solid var(--border-glass-color-header);
}

.header-actions .mob-menu-toggle {
    display: block;
    padding: 8px;
}

.mob-menu {
    position: fixed;
    height: 100%;
    top: 0;
    right: -100px;
    width: 100%;
    padding: 30px 40px 0 30px;
    max-width: 0;
    background-color: #fffffff9;
    font-size: var(--text-p-size-large);
    transition: all 500ms ease;
}
.mob-menu.active {
    display: block;
    max-width: 48em;
    right: 0;
    overflow: auto;
}
.mob-menu-content {
    position: relative;
}
.mob-menus li {
    padding: 14px 0;
}
.mob-menus li .nav-menus {
    padding-left: 16px;
    display: block;
    overflow: hidden;
    transition: all 250ms ease;
}
.mob-menus .nav-menus:not(.active) {
    max-height: 0;
}
.mob-menus .nav-menus.active {
    max-height: 500px;
    padding-top: 14px;
}
.mob-menu .socials svg {
    height: 26px;
    line-height: 26px;
    color: var(--text-color-primary);
}
.mob-menu .socials {
    margin-left: -10px;
}
.mob-menu .socials li {
    display: inline-block;
    padding: 4px 10px
}
@media only screen and (min-width: 48em) {
    .header-actions .send-email {
        display: block;
    }
    .header-actions .mob-menu-toggle, .mob-menu {
        display: none;
    }
}

@media only screen and (min-width: 78rem) {
    .desk-nav {
        display: block;
    }
    .header-container {
        padding: 0 var(--page-pad-large);
    }
}