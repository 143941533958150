section.page-projects .projects {
    padding-top: 16px;
    padding-bottom: 80px;
    max-width: 78rem;
    margin: 0 auto;
    width: 100%;
}
section.page-projects .projects > h1 {
    position: relative;
    text-align: center;
}
section.page-projects .projects > h1 img {
    width: 100%;
    max-width: 100px;
}
section.page-projects .proj-item:not(:last-child) {
    border-bottom: 1px solid var(--border-color-light);
}
section.page-projects .proj-item {
    padding: 20px 0;
}
section.page-projects .project-contact {
    max-width: 78rem;
    margin: 0 auto;
    width: 100%;
}

@media only screen and (max-width: 480px) {}

@media only screen and (min-width: 48em) {}
@media only screen and (min-width: 78rem) {}