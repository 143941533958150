body {
    margin: 0;
    font-size: var(--text-p-size-small);
    background-color: #fff;
    color: #000;
    font-family: "torus", sans-serif, "Segoe UI", "Roboto";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
html, body, section, #root, .app {
    height: 100%;
}
* {
    box-sizing: border-box;
}
ul, p {
    margin: 0;
}
ul {
    padding-left: 0;
}
li {
    list-style: none;
}
h1 {
    font-size: 28px;
    font-weight: 500;
    font-family: "torus", sans-serif, "Segoe UI", "Roboto";
}
h2 {
    font-size: 24px;
    font-weight: 500;
}
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

@font-face {
    font-family: "torus";
    font-weight: 700;
    src: url("./assets/fonts/torus-bold.woff2") format("woff2");
}
@font-face {
    font-family: "torus";
    font-weight: 600;
    src: url("./assets/fonts/torus-semibold.woff2") format("woff2");
}
@font-face {
    font-family: "torus";
    font-weight: 900;
    src: url("./assets/fonts/torus-heavy.woff2") format("woff2");
}
@font-face {
    font-family: "torus";
    font-style: italic;
    src: url("./assets/fonts/torus-italic.woff") format("woff");
}
@font-face {
    font-family: "torus";
    font-weight: 400;
    src: url("./assets/fonts/torus-light.woff2") format("woff2");
}
@font-face {
    font-family: "torus";
    font-weight: 100;
    src: url("./assets/fonts/torus-thin.woff2") format("woff2");
}
@font-face {
    font-family: "torus";
    font-weight: 500;
    src: url("./assets/fonts/torus-regular.woff2") format("regular");
}
.coming-soon {
    padding-top: 160px;
    color: var(--text-color-brand);
    font-weight: 500;
    font-size: 20px;
    text-align: center;
    width: 100%;
    margin-left: -18px;
}

@media only screen and (min-width: 48em) {
    body {
        font-size: var(--text-p-size-medium);
    }
}

@media only screen and (min-width: 78rem) {
    body {
        font-size: var(--text-p-size-large);
    }
}