.intro, .hero {
    position: relative;
    width: 100%;
    max-width: 100%;
    height: 50%;
}
.intro-greet {
    width: 100%;
    position: absolute;
    top: 36%;
    transform: translateY(-36%);
}
.intro .role {
    margin: 8px 0;
}
.btn-about {
    max-width: 124px;
}

.hero-image {
    background-image: url('../assets/images/self.png');
    background-size: contain;
    background-position: center bottom;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
}
.hero-image img {
    width: 80%;
}

@media only screen and (max-height: 512px) {
    .page-landing {
        margin-top: 100px;
        height: calc(100vh + 500px);
    }
}

@media only screen and (min-width: 48em) {
    .intro-splash {
        background-image: url('../assets/images/splash.png');
        background-size: contain;
        background-position: center left;
        background-repeat: no-repeat;
        position: absolute;
        width: 946px;
        height: 100%;
        top: -200px;
        left: -250px;
        z-index: -1;
    }
    .hero-image {
        background-position: right bottom;
        width: 66%;
        margin-left: auto;
    }
    .btn-about {
        max-width: 150px;
    }
    .intro {
        max-width: 40%;
    }
    .hero {
        position: absolute;
        top: 0;
        left: 0;
        padding-right: 30px;
    }
    .intro-greet {
        top: 20%;
        transform: translateY(-20%);
    }
    .intro, .hero {
        height: 100%;
    }
    .intro .role {
        margin: 8px 0;
    }
}

@media only screen and (min-width: 78rem) {
    .intro-splash {
        top: -120px;
    }
    .intro-greet {
        top: 36%;
        transform: translateY(-36%);
    }
}