.project .icon-proj {
    width: 100%;
    max-width: 500px;
    text-align: center;
}

.project .proj-logo {
    text-align: center;
}

.project .proj-logo,
.project .proj-content {
    position: relative;
    width: 100%;
}

.project .proj-content {
    padding-bottom: 40px;
}

.project .proj-content .title {
    font-size: 26px;
    font-weight: 400;
    line-height: 32px;
}

.project .proj-content .subtitle {
    font-size: 18px;
    font-weight: 100;
}

.project {
    flex-direction: column-reverse;
}

.proj-btn {
    max-width: 172px;
}
.icon-lock {
    color: var(--text-color-invert-light);
}
@media only screen and (min-width: 48em) {
    .project {
        flex-direction: row;
        align-items: start;
    }

    .project .proj-content {
        padding-left: 40px;
    }

    .project .proj-logo,
    .project .proj-content {
        width: 50%;
    }

    .project .proj-content .title {
        font-size: 28px;
        line-height: 34px;
    }

    .project .proj-content .subtitle {
        font-size: 22px;
    }
}

@media only screen and (min-width: 78rem) {
    .project .proj-content .title {
        font-size: 32px;
    }
}