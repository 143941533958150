.footer {
    background-image: url('../assets/images/footer_city.png');
    background-size: cover;
    box-shadow: inset 0 0 0 1000px #ffffffd1;
    background-repeat: no-repeat;
    background-position: 8% bottom;
}
.footer .footer-content {
    text-align: center;
    border-top: 1px solid var(--border-glass-color-header);
    width: 100%;
    max-width: 78rem;
    margin: 0 auto;
    padding: 20px 16px 80px 16px;
}

.footer .socials li, .footer .navs li, .footer .navs-social li {
    cursor: pointer;
    display: inline-block;
    padding: 4px 12px;
}
.footer .navs li:first-child,
.footer .socials li:first-child,
.footer .navs-social li:first-child {
    padding-left: 0;
}
.footer .navs li:last-child,
.footer .socials li:last-child,
.footer .navs-social li:last-child {
    padding-right: 0;
}
.footer .socials svg,
.footer .navs-social svg {
    height: 24px;
    line-height: 24px;
    color: var(--text-color-primary);
}
.footer .socials svg:hover,
.footer .navs-social svg:hover {
    color: var(--bg-color-hover-brand);
}
.footer .groups {
    font-size: var(--text-p-size-small);
}
.footer .navs {
    padding-bottom: 16px;
}
.footer .logo-social > * {
    width: 100%;
    padding-top: 10px;
}
.footer .logo-social > .jf-logo {
    flex-direction: column;
    margin-bottom: 10px;
    height: unset;
}
.footer .socials span {
    margin-bottom: 6px;
    display: inline-block;
}
.footer .jf-logo span {
    padding-left: 0;
    padding-top: 8px;
}
.footer .groups > div {
    padding: 2px;
}
.navs-social [title="meetup"] svg {
    height: 28px;
}
@media only screen and (min-width: 1920px) {
    .footer {
        background-size: contain;
        background-position: center bottom;
    }
}
@media only screen and (min-width: 48rem) {
    .footer .navs {
        padding-top: 24px;
    }
    .footer .jf-logo span {
        padding-left: 16px;
        padding-top: 0;
    }
    .footer .socials {
        text-align: left;
    }
    .footer .logo-social > * {
        width: unset;
        justify-content: space-between;
    }
    .footer .logo-social .jf-logo {
        flex-direction: row;
    }
}
@media only screen and (min-width: 78em) {
    .footer .logo-social span {
        font-size: 18px;
    }
}